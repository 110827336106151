export default function stickyMenu(){

function toggleFixedMenu() {
    const header = document.querySelector('.header');
    const realizationMenu = document.querySelector('.realization-menu');
    
    if (!header || !realizationMenu) {
      return;
    }
  
    const realizationMenuPosition = realizationMenu.offsetTop;
  
    const onScroll = () => {
      if (window.scrollY >= realizationMenuPosition - header.offsetHeight) {
        realizationMenu.classList.add('is-fixed');
      } else {
        realizationMenu.classList.remove('is-fixed');
      }
    };
  
    window.addEventListener('scroll', onScroll);
  }
   
function initializeSmoothScroll() {
    const smoothScrollLinks = document.querySelectorAll('.js-smooth-scroll');
  
    smoothScrollLinks.forEach(link => {
      link.addEventListener('click', function(e) {
        e.preventDefault();

        smoothScrollLinks.forEach(el => {
            el.classList.remove('active');
        });
  
        this.classList.add('active');

        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);
  
        if (targetElement) {

            const offset = window.innerWidth < 768 ? -150 : -300;
            const targetPosition = targetElement.offsetTop + offset; 
  
          window.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': targetPosition
          });
        }
      });
    });
  }
  
  toggleFixedMenu();

  initializeSmoothScroll();
}