import simpleLightbox from 'simplelightbox';
class GalleryLightbox {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.grid = this.root.querySelectorAll('[data-gallery-grid]');
        this.items = this.root.querySelectorAll('[data-gallery-item]');
        this.anchor = this.root.querySelectorAll('[data-gallery-link]');
        this.images = this.root.querySelectorAll('[data-gallery-anchor]');

        this.masonry = null;
        this.lightbox = null;
        this.currentIndex = 0;

        return true;
    }

    init() {
        this.setLightbox();
    }

    setLightbox() {
        this.lightbox = new simpleLightbox(this.images);

        this.lightbox.on('nextDone.simplelightbox', e => {
            const extLinks = document.querySelectorAll('.sl-popup-link');
            if (extLinks) extLinks.forEach(item => item.remove());

            this.currentIndex++;
            if (this.currentIndex > this.items.length - 1)
                this.currentIndex = 0;

            this.appendPopupLink(this.currentIndex);
        });

        this.lightbox.on('prevDone.simplelightbox', e => {
            const extLinks = document.querySelectorAll('.sl-popup-link');
            if (extLinks) extLinks.forEach(item => item.remove());

            this.currentIndex--;
            if (this.currentIndex < 0) this.currentIndex = this.items.length;

            this.appendPopupLink(this.currentIndex);
        });

        this.lightbox.on(
            'shown.simplelightbox',
            function (e) {
                this.appendPopupLink(0);
            }.bind(this)
        );

        this.lightbox.on('close.simplelightbox', e => {
            const extLinks = document.querySelectorAll('.sl-popup-link');
            if (extLinks) extLinks.forEach(item => item.remove());
            this.currentIndex = 0;
        });
    }

    appendPopupLink(index) {
        const wrapper = document.querySelector('.simple-lightbox');
        const popupLinkNode = document.createElement('div');
        popupLinkNode.classList.add('sl-popup-link');

        const button = this.anchor[index];
        const node = button.cloneNode(true);
        popupLinkNode.append(node);

        wrapper.append(popupLinkNode);
    }
}

const setGalleries = () => {
    const sections = document.querySelectorAll('[data-gallery]');
    if (sections) sections.forEach(section => new GalleryLightbox(section));
};

export default setGalleries;
