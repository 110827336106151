import Aos from 'aos';
import megamenu from "./modules/mega-menu";
import burgerMenu from "./modules/mobile-menu";
import languageSwitcher from "./modules/language-switcher";
import setGalleries from './modules/lightbox';
import modalContact from './modules/modal-contact';
import stickyMenu from './modules/realization-menu';
/**
 * Import Boostrap modules
 */
import { Collapse, Modal, Tooltip } from "bootstrap";

var documentReady = function () {
    burgerMenu();
    languageSwitcher();
    megamenu();
    setGalleries();
    modalContact();
    stickyMenu();

    Aos.init({
        once: true,
    });
};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener("DOMContentLoaded", documentReady);
}
