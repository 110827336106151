export default function megamenu() {
    const $megamenuChildrenClass = $('.has-children');

    $megamenuChildrenClass.on('mouseover', function(){
        $(this).find('.js-dropdown').addClass('-active');
        $(this).children('.header-menu__link').addClass('--active');
    });

    $megamenuChildrenClass.on('mouseout', function () {
        $(this).find('.js-dropdown').removeClass('-active');
        $(this).children('.header-menu__link').removeClass('--active');
    });


}